import React, { useState } from "react";

import { faFaceFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryParam, StringParam } from "use-query-params";

import {
  OPTION,
  OPTION as LANGUAGE_OPTION,
  Type as LanguageType,
} from "../utils/language";

import Context from "../utils/context";

import Layout from "../components/Layout";

const metadata = {
  title: "Mi Quinceañera | Adriana Quiroz",
  description: "Mi Quince Party",
  robots:
    "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  canonical: "/",
  "og:locale": "en_US",
  "og:type": "website",
  "og:title": "Mi Quince | Adriana Quiroz",
  "og:description": "Mi Quince Party",
  "og:url": "/",
  "og:site_name": "Mi Quinceañera",
  "og:image": "/src/images/icon.png",
  "article:modified_time": "2022-06-22T13:57:48+00:00",
  "twitter:card": "summary_large_image",
  "twitter:label1": "Est. reading time",
  "twitter:data1": "1 minute",
};

const IndexPage = () => {
  const [languageQuery] = useQueryParam("language", StringParam);
  const [language, setLanguage] = useState<LanguageType>(
    languageQuery?.toLowerCase() === "spanish"
      ? LANGUAGE_OPTION.SPANISH
      : LANGUAGE_OPTION.ENGLISH
  );

  return (
    <Context.Provider value={[language, setLanguage]}>
      <Layout metadata={metadata}>
        <div className="h-full flex flex-col justify-center items-center text-white">
          <FontAwesomeIcon
            icon={faFaceFrown}
            size="5x"
            className="mb-4 text-pink"
          />
          <div className="uppercase text-5xl mb-4">
            {language === OPTION.ENGLISH ? "Sorry" : "Album de Firmas"}
          </div>
          <div className="text-xl">
            {language === OPTION.ENGLISH
              ? "This page doesn't exist."
              : "Album de Firmas"}
          </div>
        </div>
      </Layout>
    </Context.Provider>
  );
};

export default IndexPage;
